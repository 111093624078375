/* eslint react/jsx-key: off */
import React from "react";
import {
  SaveButton,
  TextInput,
  Toolbar,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedShowLayout,
  ArrayInput,
} from "react-admin";

import Aside from "./Aside";
import Edit from "../../EditNoUndo";
import { makeStyles } from "@mui/styles";

import ContainerTitle from "./ContainerTitle";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const ContainerEditToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
    </Toolbar>
  );
};

const ContainerEdit = ({ permissions, ...props }) => {

  return (
    <Edit
      title={<ContainerTitle />}
      aside={<Aside />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Container">
          <SimpleForm toolbar={<ContainerEditToolbar />}>
            <ArrayInput source="nasdaq_tickers">
              <SimpleFormIterator inline>
                <TextInput label="String" source="" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};

export default ContainerEdit;
