import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useGetOne,
  useRecordContext,
  TextField,
  useUpdate,
} from "react-admin";
import { Popper, Alert, AlertTitle } from "@mui/material";
import { SEARCH_EVENT_NAMES } from "../../graphql/classification_events";
import AutocompleteInput from "./AutocompleteInput";
import Aside from "./Aside";

const styles = () => ({
  popper: {
    width: "fit-content",
  },
});

const CustomPopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

/**
 * A react component for editing a classification event.
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The ClassificationEventEdit component.
 */
const ClassificationEventEditForm = () => {
  const record = useRecordContext();

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [update, { data: dataClassificationEvent }] = useUpdate();

  const { data } = useQuery(SEARCH_EVENT_NAMES, {
    variables: {
      query: inputValue.trim(),
      linktoken_id: record?.linked_account_id,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.searchEventNames?.length) {
      const newOptions = data?.searchEventNames?.map((event) => ({
        option_name: event.goal_id
          ? `(GA3) Goal Id: ${event.goal_id} - Goal Name: ${event.name}`
          : `(GA4/AA) Event Name: ${event.event_name}`,
        ...event,
      }));
      setOptions(newOptions);
    } else {
      setOptions([]);
    }
  }, [data]);

  // calls the resource in dataProvider to get linktoken
  const {
    data: linktoken,
    loading,
    error,
  } = useGetOne("Linktoken", {
    id: record?.linked_account_id,
  });

  /**
   * Client side check before submitting form
   * @param {Object} values
   */
  const handleSubmit = (values) => {
    const newErrors = {}; // initialize new errors object
    if (record.type == "GOOGLE_VIEW" && isNaN(values?.goal_id)) {
      newErrors.goal_id =
        "Goal ID is required for this linktoken type. Please verify a valid number was entered ";
    }

    setErrors(newErrors); // set the errors state with the new errors object

    if (Object.keys(newErrors).length === 0) {
      update("ClassificationEvent", { id: values.id, data: values });
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <SimpleForm onSubmit={handleSubmit}>
      {Object.keys(errors).length > 0 && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {Object.keys(errors).map((key) => (
            <p key={key}>{errors[key]}</p>
          ))}
        </Alert>
      )}

      <ul>
        <li>
          For GA3 goals, event_name is optional.
          <br />
          Use this format for goal_id: 1 instead of 001
        </li>
        <li>For GA4 events, event_name is the only required field</li>
        <li>
          For Adobe Analytics, the evar (eg, evar1) goes into event_name.
          <br />
          Use this format: variables/evarX or variables/propX
        </li>
        <li>
          For Adobe Analytics, selective evar values should be entered into the
          Event value (AA) text field if you want to reclassify a specific evar
          and its value.
          <br />
          Evar values must be exact match and is case sensitive.
        </li>
      </ul>

      <TextInput source="linked_account_id" disabled validate={required()} />

      {["GOOGLE_WEBPROPERTY_G4", "AA_SEGMENT"].includes(linktoken?.type) && (
        <>
          <TextField source="event_name" />
          <AutocompleteInput
            sx={{
              width: 400,
              marginBottom: 2,
            }}
            PopperComponent={CustomPopper} // renders auto complete options so list fits
            source="event_name" // name of the record to bind to
            choices={options || []} // list of objects as an option
            optionText="option_name" // field to display user friendly option label
            optionValue="event_name" // field to select and pass to GQL mutation
            label="Event name (GA4/AA)"
            onInputChange={(e) => {
              // Calls GQL to search when user input changes
              return setInputValue(e?.target?.value || "");
            }}
          />
        </>
      )}

      {["AA_SEGMENT"].includes(linktoken?.type) && (
        <>
          <TextField source="event_value" />
          <TextInput label="Event value (AA)" source="event_value" />
        </>
      )}

      {["GOOGLE_VIEW"].includes(linktoken?.type) && (
        <>
          <TextField source="goal_id" />
          <AutocompleteInput
            sx={{
              width: 400,
              marginBottom: 2,
            }}
            PopperComponent={CustomPopper} // renders auto complete options so list fits
            freeSolo
            source="goal_id" // name of the record to bind to
            choices={options || []} // list of objects as an option
            optionText="option_name" // field to display user friendly option label
            optionValue="goal_id" // field to select and pass to GQL mutation
            label="Goal Id (GA3)"
            onInputChange={(e) => {
              // Calls GQL to search when user input changes
              return setInputValue(e?.target?.value || "");
            }}
          />
        </>
      )}

      <SelectInput
        validate={required()}
        source="classification"
        choices={[
          { id: "INTERACTION", name: "INTERACTION" },
          { id: "CONVERSION", name: "CONVERSION" },
        ]}
      />

      <p>
        If a goal, event, or evar is not classified, it will default as
        CONVERSION
      </p>
    </SimpleForm>
  );
};

/**
 * A react component for displaying a classification edit form.
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The ClassificationEventEdit component.
 */
const ClassificationEventEdit = (props) => {
  const redirect = (basePath, id, data) => {
    return `ClassificationEvent/${id}`;
  };

  return (
    <Edit {...props} aside={<Aside />} redirect={redirect}>
      <ClassificationEventEditForm />
    </Edit>
  );
};

export default ClassificationEventEdit;
