/* eslint react/jsx-key: off */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  SaveButton,
  Toolbar,
  SimpleForm,
  SelectInput,
  required,
  useRecordContext,
  useDataProvider,
  useEditContext,
  Tab,
  TabbedShowLayout,
  TopToolbar,
  ShowButton,
  TextInput,
} from "react-admin";

import Edit from "../../EditNoUndo";

import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

import LinktokenTitle from "./LinktokenTitle";
import Aside from "./Aside";
import JsonInput from "../AlembicJsonFields/JsonInput";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save button only
 */
const LinktokenEditToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
    </Toolbar>
  );
};

/**
 * Gets list of containers filter by organization id to move a LT into
 * @returns {JSX.Element} The MoveLinkTokenContainer component.
 */
const MoveLinkTokenContainer = () => {
  const [containers, setContainers] = React.useState([]);

  const dataProvider = useDataProvider();
  const record = useRecordContext();

  useEffect(() => {
    if (record?.organization?.id) {
      dataProvider
        .getList("Container", {
          filter: { organizationId: record.organization.id },
          pagination: { page: 1, perPage: 1000 },
        })
        .then(({ data }) => {
          data.unshift({ id: null, name: "Orphan" });
          setContainers(data);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [record]);

  if (containers.length === 0) {
    return <></>;
  }

  return (
    <SelectInput
      label="container_id"
      source="container_id"
      choices={containers.map((container) => ({
        id: container.id,
        name: `ID: ${container.id} - ${container.name}`,
      }))}
    />
  );
};

/**
 * A react component for overriding linktoken actions.
 * @returns {JSX.Element} The LinktokenEdit component.
 */
const LinktokenEditActions = () => {
  const editContext = useEditContext();

  return (
    <TopToolbar>
      <ShowButton />
      {/* Custom create classification button */}
      {[
        // GA3 has 3 tiers, GA account -> GA properties (many) -> All Web Site Data (default view)
        // all classification on GA3 views
        "GOOGLE_VIEW",
        "GOOGLE_WEBPROPERTY_G4",
        "AA_SEGMENT",
      ].includes(editContext?.record?.type) && (
          <Button
            color="primary"
            href={`/#/ClassificationEvent/create?source=${JSON.stringify({
              linked_account_id: editContext?.record?.id,
              type: editContext?.record?.type,
            })}`}
          ><>
              <AddIcon /> Create Classification Event
            </>
          </Button>
        )}
    </TopToolbar>
  );
};

/**
 * Global form validator for the LinktokenEdit form
 * @param {Object} values 
 * @returns 
 */
const validateLinktokenForm = values => {
  const errors = {};
  console.log(values);

  if (values.enabled && values.container_id == null) {
    errors.container_id = "You must set a container ID to enable a linktoken";
  }

  return errors;
};

/**
 * A react component for editing a linktoken.
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The LinktokenEdit component.
 */
const LinktokenEdit = ({ ...props }) => {
  return (
    <Edit
      actions={<LinktokenEditActions />}
      title={<LinktokenTitle />}
      aside={<Aside />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Linktoken">
          <SimpleForm toolbar={<LinktokenEditToolbar />} validate={validateLinktokenForm}>
            <SelectInput
              label="Enable / Disable LT"
              source="enabled"
              validate={required()}
              choices={[
                { id: true, name: "Enabled" },
                { id: false, name: "Disabled" },
              ]}
            />
            <MoveLinkTokenContainer />

            <JsonInput
              source="analytics_metrics_by_dimensions_dict"
              label="Adobe Analytics Metrics by Dimensions Config"
            />
            <TextInput
              source="tte_outcome"
              label="TTE Outcome Stage Name"
            />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};

LinktokenEdit.propTypes = {
  id: PropTypes.any,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default LinktokenEdit;
