// in src/posts.js
import * as React from "react";
import {
    Edit, SimpleForm, TextInput, DateTimeInput, DateInput, SelectInput,
    required, useRecordContext, useEditContext, useTranslate
} from 'react-admin';

import Aside from "./Aside";

const AnnotationTitle = () => {
    const record = useRecordContext();
    const { isLoading } = useEditContext();
    const translate = useTranslate();

    if (isLoading) return null;

    return (
        <span>
            {record
                ? translate("annotation.edit.title", {
                    id: record.id,
                    message: record.message,
                })
                : ""}
        </span>
    );
};

const AnnotationEdit = (props) => (
    <Edit {...props}
        title={<AnnotationTitle />}
        aside={<Aside />}
    >
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput disabled label="Created by User Id" source="user_id" />
            <TextInput disabled label="Organization Id" source="organization_id" />
            <TextInput disabled label="Container Id" source="container_id" />
            <TextInput multiline source="message" validate={required()} />

            <SelectInput source="message_type" choices={[
                { id: 'U', name: 'User Message' },
                { id: 'S', name: 'System Message' },
            ]} />

            <DateInput source="start_date" />
            <DateInput source="end_date" />

            <DateTimeInput disabled label="Created at" source="created_at" />
            <DateTimeInput disabled label="Updated at" source="updated_at" />
            <DateTimeInput disabled label="Deleted at" source="deleted_at" />

        </SimpleForm>
    </Edit>
);

export default AnnotationEdit;