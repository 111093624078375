/* 
Data Provider architecture
A Data Provider must have the following methods:

const dataProvider = {
    getList:    (resource, params) => Promise,
    getOne:     (resource, params) => Promise,
    getMany:    (resource, params) => Promise,
    getManyReference: (resource, params) => Promise,
    create:     (resource, params) => Promise,
    update:     (resource, params) => Promise,
    updateMany: (resource, params) => Promise,
    delete:     (resource, params) => Promise,
    deleteMany: (resource, params) => Promise,
}
*/

// in src/dataProvider.js
import { buildQuery } from "ra-data-graphql-simple";

import { AUDIT_ENTRY_ONE, AUDIT_ENTRY_LIST } from "./graphql/audit_entries";

import {
  ANNOTATION_CREATE,
  ANNOTATION_ONE,
  ANNOTATION_LIST,
  ANNOTATION_DELETE,
  ANNOTATION_UPDATE,
} from "./graphql/annotations";

import {
  CONTAINER_ONE,
  CONTAINER_LIST,
  MOVE_CONTAINER,
  UPDATE_NASDAQ_TICKERS
} from "./graphql/container";

import { ECDCONFIG_ONE } from "./graphql/ecdconfig";

import {
  NOTIFICATION_CREATE,
  NOTIFICATION_ONE,
  NOTIFICATION_LIST,
  NOTIFICATION_DELETE,
  NOTIFICATION_UPDATE,
} from "./graphql/notifications";

import { ORG_ONE, ORG_LIST, ORG_UPDATE } from "./graphql/organization";

import { LINKTOKEN_ONE, LINKTOKEN_LIST } from "./graphql/linktoken";

import { USERS_ONE, USERS_LIST } from "./graphql/users";

import { USER_CREATE } from "./graphql/user";

import { BECOME_USER } from "./graphql/admin";

import {
  CLASSIFICATION_EVENT_CREATE,
  CLASSIFICATION_EVENT_ONE,
  CLASSIFICATION_EVENT_LIST,
  CLASSIFICATION_EVENT_UPDATE,
  CLASSIFICATION_EVENT_DELETE,
  SEARCH_EVENT_NAMES,
} from "./graphql/classification_events";

import {
  PROMPT_TEMPLATE_ONE,
  PROMPT_TEMPLATE_LIST,
  PROMPT_TEMPLATE_CREATE,
  PROMPT_TEMPLATE_UPDATE
} from "./graphql/prompt_templates";

const getNumber = (value, defaultValue) => {
  const num = parseInt(value, 10);
  return isNaN(num) ? defaultValue : num;
};

const myBuildQuery = (introspection) => (fetchType, resource, params) => {
  // first off, do we have this available directly in the graphql schema? We can
  // try to automatically build it off of the introspection.
  let builtQuery;

  try {
    builtQuery = buildQuery(introspection)(fetchType, resource, params);
  } catch (e) {
    // we don't have it, so we need to build it ourselves
    builtQuery = null;
  }

  // uncomment the next block to debug data provider requests. Extremely helpful
  // when you have to rewrite or transform queries. If you don't see your
  // queries in introspection, you may not have them in the graphQL schema, or,
  // you may not have the single-object resolver available. (e.g.
  // Linktoken/Organization/etc.) Check the introspection.resources object to
  // see if it got picked up. --jna
  //
  // the single query resolver must exist or buildQuery silently fails

  console.log(
    `req: fetchType: ${fetchType} resource: ${resource} builtQueryFound?: ${builtQuery !== null
    }
           params: ${JSON.stringify(params)}`
  );

  // Please also note that for GET_MANY the API must return the full records
  // requested. There is a subtle bug where if the pageSize is limited to say,
  // 10, and then react admin generates a request for hundreds of rows the cache
  // inside of react-query will invalidate, causing react admin to flush the
  // cache. This often exhibits as a blank column for a small number of records
  // in lists and other tables. -- jna 10/28/22

  // Override GraphQL queries here to make them a bit more sane
  switch (resource) {
    case "Annotation":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: ANNOTATION_UPDATE,
            variables: {
              data: {
                id: params.id,
                user_id: params.user_id,
                organization_id: params.data.organization_id,
                container_id: params.data.container_id,
                start_date: params.data.start_date,
                end_date: params.data.end_date,
                message: params.data.message,
                message_type: params.data.message_type,
              },
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.updateAdminAnnotation,
                },
              };
            },
          };
        case "DELETE":
          return {
            query: ANNOTATION_DELETE,
            variables: {
              id: params.id,
            },
          };
        case "CREATE":
          return {
            query: ANNOTATION_CREATE,
            variables: {
              data: params.data,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createAdminAnnotation,
                },
              };
            },
          };
        case "GET_LIST":
          return { ...builtQuery, query: ANNOTATION_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: ANNOTATION_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: ANNOTATION_ONE,
          };
        default:
      }
      break;
    case "AuditEntry":
      switch (fetchType) {
        case "GET_LIST":
          return { ...builtQuery, query: AUDIT_ENTRY_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: AUDIT_ENTRY_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: AUDIT_ENTRY_ONE,
          };
        default:
      }
      break;
    case "Become":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: BECOME_USER,
            variables: {
              id: parseInt(params.id, 10),
            },
            parseResponse: (response) => {
              return {
                data: {
                  // fake this out because the API doesn't return an ID.
                  id: response.data.becomeUser.user.id,
                  ...response.data.becomeUser,
                },
              };
            },
          };
        default:
      }
      break;
    case "Container":
      switch (fetchType) {
        case "GET_MANY_REFERENCE": /* used when filtering containers by org */
        case "GET_MANY":
          return { ...builtQuery, query: CONTAINER_LIST };
        case "GET_LIST":
          return { ...builtQuery, query: CONTAINER_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: CONTAINER_ONE };
        case "UPDATE":
          if (params.data.nasdaq_tickers) {
            return {
              query: UPDATE_NASDAQ_TICKERS,
              variables: {
                id: params.id,
                nasdaq_tickers: params.data.nasdaq_tickers
              },
              parseResponse: (response) => {
                return {
                  data: {
                    ...response.data.updateContainer,
                  },
                };
              },
            };
          }

          return {
            query: MOVE_CONTAINER,
            variables: {
              id: params.id,
              parent_id: params.data.new_parent_id,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.moveContainer,
                },
              };
            },
          };
      }
      break;
    case "ECDConfig":
      switch (fetchType) {
        case "GET_ONE":
          return { ...builtQuery, query: ECDCONFIG_ONE };
        default:
      }
      break;
    case "Linktoken":
      switch (fetchType) {
        case "GET_LIST":
          return { ...builtQuery, query: LINKTOKEN_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: LINKTOKEN_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: LINKTOKEN_ONE };
        default:
      }
      break;

    case "User":
      switch (fetchType) {
        case "CREATE":
          return {
            query: USER_CREATE,
            variables: {
              first_name: params.data.first_name,
              last_name: params.data.last_name,
              email: params.data.email,
              time_zone: params.data.time_zone,
              title: params.data.title,
              password: params.data.password,
              organization_id: params.data.organization_id,
              home_container_id: params.data.home_container_id,
              role_id: params.data.role_id,
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createUserOnOrganization,
                },
              };
            },
          };
        case "GET_MANY_REFERENCE":
        case "GET_LIST":
          return { ...builtQuery, query: USERS_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: USERS_ONE };
        default:
      }
      break;
    case "Notification":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: NOTIFICATION_UPDATE,
            variables: {
              data: {
                id: params.id,
                to_user_id: getNumber(params.data.to_user_id, null),
                container_id: getNumber(params.data.container_id, null),
                organization_id: getNumber(params.data.organization_id, null),

                scope: params.data.scope,
                subject: params.data.subject,
                body: params.data.body,

                cta_object_type: params.data.cta_object_type,
                cta_object_id: getNumber(params.data.cta_object_id, null),
                url: params.data.url,
              },
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.updateAdminNotification,
                },
              };
            },
          };
        case "DELETE":
          return {
            query: NOTIFICATION_DELETE,
            variables: {
              id: params.id,
            },
          };
        case "CREATE":
          return {
            query: NOTIFICATION_CREATE,
            variables: {
              data: {
                to_user_id: getNumber(params.data.to_user_id, null),
                container_id: getNumber(params.data.container_id, null),
                organization_id: getNumber(params.data.organization_id, null),

                scope: params.data.scope,
                subject: params.data.subject,
                body: params.data.body,

                cta_object_type: params.data.cta_object_type,
                cta_object_id: getNumber(params.data.cta_object_id, null),
                url: params.data.url,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createAdminNotification,
                },
              };
            },
          };
        case "GET_LIST":
          return { ...builtQuery, query: NOTIFICATION_LIST };
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: NOTIFICATION_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: NOTIFICATION_ONE,
          };
        default:
      }
      break;
    case "Organization":
      // the full organization graphQL derived from the introspection query call
      // asks for sub-objects and other related items. here we override the
      // query to only return the id and name of the organization and retain the
      // parsing functions.
      switch (fetchType) {
        case "GET_MANY_REFERENCE":
        case "GET_LIST":
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return {
            ...builtQuery,
            query: ORG_LIST,
            parseResponse: (response) => {
              console.log("get_list response", response);
              return {
                data: response.data.items,
                total: response.data.total.count,
              };
            },
          };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: ORG_ONE,
            parseResponse: (response) => {
              console.log("get_one response", response);
              return response.data;
            },
          };
        case "UPDATE":
          return {
            query: ORG_UPDATE,
            variables: {
              data: {
                id: params.id,
                user_id: params.data.user_id,
                account_manager_user_id: params.data.account_manager_user_id,
                name: params.data.name,
                company_short_name: params.data.company_short_name,
                account_manager_user_id: params.data.account_manager_user_id,
                customer_type: params.data.customer_type,
                account_type: params.data.account_type,
                max_containers: params.data.max_containers,
                max_users: params.data.max_users,
                week_start: params.data.week_start,
                quarter_start: params.data.quarter_start,
                mfa_mandatory: params.data.mfa_mandatory,
              },
            },
            parseResponse: (response) => {
              console.log("response", response);
              console.log(
                "response.data.updateOrganization",
                response.data.updateOrganization
              );
              return {
                data: {
                  ...response.data.updateOrganization,
                },
              };
            },
          };
        default:
      }
      break;
    case "ClassificationEvent":
      switch (fetchType) {
        case "UPDATE":
          return {
            query: CLASSIFICATION_EVENT_UPDATE,
            variables: {
              data: {
                id: params.id,
                linked_account_id: params.data.linked_account_id,
                goal_id: params.data.goal_id,
                event_name: params.data.event_name,
                event_value: params.data.event_value,
                classification: params.data.classification,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.updateAdminClassificationEvent,
                },
              };
            },
          };
        case "DELETE":
          return {
            query: CLASSIFICATION_EVENT_DELETE,
            variables: {
              id: params.id,
            },
          };
        case "CREATE":
          return {
            query: CLASSIFICATION_EVENT_CREATE,
            variables: {
              data: {
                linked_account_id: params.data.linked_account_id,
                goal_id: params.data.goal_id,
                event_name: params.data.event_name,
                event_value: params.data.event_value,
                classification: params.data.classification,
              },
            },
            parseResponse: (response) => {
              return {
                data: {
                  ...response.data.createAdminClassificationEvent,
                },
              };
            },
          };
        case "GET_LIST":
          return { ...builtQuery, query: CLASSIFICATION_EVENT_LIST };
        case "GET_MANY":
          const { action, variables } = params;

          // custom resource method for searching event names
          if (action == "SEARCH_EVENT_NAMES") {
            return {
              ...builtQuery,
              query: SEARCH_EVENT_NAMES,
              variables,
            };
          }

          return { ...builtQuery, query: CLASSIFICATION_EVENT_LIST };
        case "GET_ONE":
          return {
            ...builtQuery,
            query: CLASSIFICATION_EVENT_ONE,
          };
        default:
      }
      break;
    case "PromptTemplate": 
      switch (fetchType) {
        case "CREATE":
          return {
            query: PROMPT_TEMPLATE_CREATE,
            variables: {
              data: params.data,
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.createAdminPromptTemplate,
                },
              };
            },
          };
        case "UPDATE":
          return {
            query: PROMPT_TEMPLATE_UPDATE,
            variables: {
              data: {
                id: params.id,
                container_id: params.data.container_id,
                stages: params.data.stages,
                is_default: params.data.is_default,
              },
            },
            parseResponse: (response) => {
              // we have to convert the response to the format that react-admin
              // accepts or we'll get an error
              return {
                data: {
                  ...response.data.updateAdminPromptTemplate,
                },
              };
            },
          };
        case "GET_LIST":
        case "GET_MANY": // calls all but uses ids[] filter to select objects
          return { ...builtQuery, query: PROMPT_TEMPLATE_LIST };
        case "GET_ONE":
          return { ...builtQuery, query: PROMPT_TEMPLATE_ONE };
        default:
      }
      break;
    default:
  }

  if (!builtQuery) {
    throw new Error(
      `Unknown resource ${resource}, fetchtype ${fetchType} - nothing in derived schema or overrides.`
    );
  }
  console.log("builtQuery", builtQuery);

  return builtQuery;
};

export default myBuildQuery;
