import React from "react";
import {
  Datagrid,
  List,
  DateField,
  TextField,
  TextInput,
  ReferenceField,
  BooleanField,
  ShowButton,
  EditButton,
  Pagination,
} from "react-admin";

import JsonField from "../AlembicJsonFields/JsonField";

// import Aside from "./Aside";

const PromptTemplateFilters = [
  <TextInput label="Container ID" source="containerID" defaultValue="" />,
];

const PromptTemplatePagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const PromptTemplatePanel = () => (
  <JsonField source="stages" label="Stages" fullWidth />
);

const PromptTemplateList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={PromptTemplateFilters}
    sort={{
      field: "id",
      order: "ASC",
    }}
    // aside={<Aside />}    
    pagination={<PromptTemplatePagination />}
  >
    <Datagrid optimized bulkActionButtons={false} expand={<PromptTemplatePanel />}>
      <TextField source="id" />
      <ReferenceField
        label="Container"
        source="container_id"
        reference="Container"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="is_default" />

      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />

      <EditButton label="Edit" />
      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default PromptTemplateList;
