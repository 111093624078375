import { gql } from "@apollo/client";

export const ECDCONFIG_ONE = gql`
  query ECDConfig($id: ID!) {
    data: ECDConfig(id: $id) {
      id
      container_id
      created_at
      updated_at
      enable_ga_events
      enable_aa_events
      enable_broadcasts_events
      enable_podcasts_events
      enable_social_animal_events
      enable_impressions_events
      enable_coincidence_events
      global_start_date
      cd_min_rel_rank_dict
      cd_min_rel_rank_default
      tsmoothie_sigma
      max_duration_days
      peak_win_size
      peak_cutoff_th
      mag_base_look_back_days
      min_daily_initial_group_size
      cd_match_norm_threshold
      ga_product_name_replace_pattern
      ga_item_name_replace_pattern
      ga_min_metric_sum_all_time
      ga_metric_pct_of_total_cutoff
      ga_max_time_series_count
      aa_min_metric_sum_all_time
      aa_metric_pct_of_total_cutoff
      aa_max_time_series_count
      peak_event_min_magnitude_dict
      peak_event_min_magnitude
      min_median_n
      cd_split_threshold_days
      cd_split_sensitivity
    }
  }
`;
