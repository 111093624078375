// in src/posts.js
import * as React from "react";
import {
  Edit, SimpleForm, TextInput, DateTimeInput, SelectInput,
  required, useRecordContext, useEditContext, useTranslate
} from 'react-admin';


import Aside from "./Aside";

import URL from 'url';

const validateURL = (s) => {
  try {
    new URL(s);
    return true;
  } catch (err) {
    return false;
  }
};

const NotificationTitle = () => {
  const record = useRecordContext();
  const { isLoading } = useEditContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return (
    <span>
      {record
        ? translate("notification.edit.title", {
          id: record.id,
          subject: record.subject,
        })
        : ""}
    </span>
  );
};

const NotificationEdit = (props) => (
  <Edit {...props}
    title={<NotificationTitle />}
    aside={<Aside />}
  >
    <SimpleForm>
      <DateTimeInput disabled label="Created at" source="created_at" />
      <DateTimeInput disabled label="Updated at" source="updated_at" />
      <DateTimeInput disabled label="Deleted at" source="deleted_at" />

      <SelectInput source="scope" choices={[
        { id: 'ALL', name: 'All Users' },
        { id: 'CONTAINER', name: 'Container Message' },
        { id: 'ORGANIZATION', name: 'Organization Message' },
        { id: 'USER', name: 'User Message' },
      ]} />

      <TextInput disabled label="Id" source="id" />
      <TextInput disabled label="From User ID" source="from_user_id" />
      <TextInput disabled label="To User ID" source="to_user_id" />
      <TextInput disabled label="Target Organization Id" source="organization_id" />
      <TextInput disabled label="Target Container Id" source="container_id" />

      <TextInput source="subject" validate={required()} />
      <TextInput multiline source="body" validate={required()} />

      <p>
        Determines where clicking on the notification takes the user (optional)
      </p>

      <SelectInput source="cta_object_type" choices={[
        { id: 'assets', name: 'Asset' },
        { id: 'container', name: 'Container' },
        { id: 'campaign_events', name: 'Campaign Event' },
        { id: 'linktoken', name: 'Linktoken' },
        { id: 'themes', name: 'Theme' },
        { id: 'topics', name: 'Topic' },
        { id: 'users', name: 'User' },
        { id: 'url', name: 'URL' }
      ]} />

      <p>
        If the above is set, we'll take the user to this object id based on the above type (ignored in current UI)
      </p>
      <TextInput source="cta_object_id" label="CTA Object ID" />

      <p>
        If URL is specified, please enter a valid URL to link to.
      </p>
      <TextInput source="url" label="URL" validate={validateURL} />

    </SimpleForm>
  </Edit>
);

export default NotificationEdit;