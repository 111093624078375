import { buildFullAccessFor } from "ra-auth-acl";

// getPermissions returns a permission based on a specific user role.
//
// the format of this file is "Role: Object: Action: boolean"
// ra-acl then sets up the access control for each role.
const permissions = {
  admin: {
    // all models should be listed here.
    ...buildFullAccessFor([
      "Organization",
      "Container",
      "ECDConfig",
      "Linktoken",
      "AuditEntry"
    ]),
    // remove all edit functionality for right now.
    User: {
      enabled: true,
      list: true,
      create: false,
      edit: false,
      show: true,
      delete: false,
      become: true,
    },
    ECDConfig: {
      enabled: true,
      edit: true,
      create: true,
      show: true,
    },
    Linktoken: {
      enabled: true,
      edit: false,
      create: false,
      show: true,
    },
    AuditEntry: {
      enabled: true,
      edit: false,
      create: false,
      show: true,
    },
  },
  viewer: {
    User: {
      enabled: true,
      list: true,
      show: true,
      become: false,
    },
    Organization: {
      enabled: true,
      list: true,
      show: true,
    },
    Container: {
      enabled: true,
      list: true,
      show: true,
    },
    AuditEntry: {
      enabled: true,
      list: true,
      show: true,
    },
    ECDConfig: {
      show: true,
    },
  },
  user: {
    // we will probably never use this, but here it is.
    User: {
      enabled: false,
    },
    Organization: {
      enabled: false,
    },
    Container: {
      enabled: false,
    },
    ECDConfig: {
      enabled: false,
    },
  },
};

export default permissions;
