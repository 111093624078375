import React from "react";
import {
  ShowButton,
  SaveButton,
  CloneButton,
  TopToolbar,
  Toolbar,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput,
  Tab,
  TabbedShowLayout,
} from "react-admin";

import Edit from "../../EditNoUndo";

import { makeStyles } from "@mui/styles";

import PromptTemplateTitle from "./PromptTemplateTitle";
// import Aside from "./Aside";
import JsonInput from "../AlembicJsonFields/JsonInput";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const PromptTemplateEditActions = () => (
  <TopToolbar>
    <CloneButton />
    <ShowButton />
  </TopToolbar>
);

/**
 * Custom Toolbar for the Edit form
 *
 * Save button only
 */
const PromptTemplateEditToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
    </Toolbar>
  );
};

const optionRenderer = (choice) => `${choice.name} (${choice.id})`;

const transform = (values) => {
  const { container_id, stages, is_default } = values;

  // pass null container_id if none provided to allow update of vanilla default
  return { container_id: container_id || null, stages, is_default };
}

const PromptTemplateEdit = (props) => (
  <Edit
    {...props}
    actions={<PromptTemplateEditActions />}
    title={<PromptTemplateTitle edit />}
    transform={transform}
    // aside={<Aside />}
  >
    <TabbedShowLayout>
      <Tab label="Prompt Template">
        <SimpleForm toolbar={<PromptTemplateEditToolbar />}>
          <ReferenceInput        
            label="Container"
            source="container_id"
            reference="Container"
            sort={{ field: "name", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput sx={{ width: '400px' }} optionText={optionRenderer} />
          </ReferenceInput>

          <BooleanInput source="is_default" label="Default" />

          <JsonInput source="stages" label="Groups JSON" />
        </SimpleForm>
      </Tab>
    </TabbedShowLayout>
  </Edit>
);

export default PromptTemplateEdit;
