/* eslint-disable no-console */
// in src/authProvider.js
import client from "./middleware/apolloClient";
import permissions from "./permissions";
import { SIGN_IN, BECOME_USER_LOGIN } from "./graphql/sign_in";
import { GET_ME, USER_AUTH_OTP } from "./graphql/user";

/**
 *
 * This acts as an authentication provider for the react admin system.
 * SESSION_NAME keeps track of who you are logged in as in localStorage.
 * The API tracks your access via a signed express-session cookie.
 *
 * Upon Sign-in, we also send back an expires time. When that is reached the
 * frontend must force the user to re-authenticate.
 *
 */
export const SESSION_NAME = "currentUser";
export const MFA_SESSION = "mfaSession";
export const JWT_TOKEN = "jwtToken";
export const MIN_ADMIN_LEVEL = 10;

export const getRoleFromAdminLevel = (level) => {
  if (level >= 100) {
    return "admin"; // no restrictions
  }

  if (level >= 50) {
    return "viewer"; // read-only.
  }

  return "user";
};

/**
 * clears the login from localStorage. no arguments.
 */
const clearLogin = () => {
  console.log("clearLogin");
  localStorage.removeItem(SESSION_NAME);
  localStorage.removeItem(MFA_SESSION);
  localStorage.removeItem(JWT_TOKEN);
};

/**
 * logs in with a token, typically from returning from admin.
 */
const loginWithToken = async (token) => {
  // this will throw if not successful.
  const result = await client.mutate({
    variables: {
      token: token,
    },
    mutation: BECOME_USER_LOGIN,
    fetchPolicy: "no-cache",
  });

  localStorage.setItem(
    SESSION_NAME,
    JSON.stringify({
      expires: result.data.becomeUserLogin.expires,
      user: result.data.becomeUserLogin.user,
    })
  );

  localStorage.setItem(MFA_SESSION, true);
  localStorage.setItem(JWT_TOKEN, result.data.becomeUserLogin.token);

  console.log("loginFromToken: successful");
  return true;
}

/**
 * rejects a login by returning a rejected promise with code and logs reason to console
 * @param {String} reason - reason for rejection
 * @param {String} code - a unique code for the error returned in the promise.
 * @returns
 */
const rejectLogin = (reason, code) => {
  clearLogin();
  console.log(`${code} - ${reason}`);
  return Promise.reject(new Error(code));
};

export default {
  login: async ({ username, password, code }) => {
    try {
      // new login sessions flush the MFA session state
      clearLogin();
      const result = await client.mutate({
        variables: {
          user: username,
          password,
        },
        mutation: SIGN_IN,
        fetchPolicy: "no-cache",
      });

      if (result?.data?.signIn) {
        if (result.data.signIn?.user.admin_level <= MIN_ADMIN_LEVEL) {
          // admin level of at least 10 required for login
          return rejectLogin(
            "auth.insufficient_admin_level",
            "insufficient_admin_level"
          );
        }
        console.log("signed in with correct admin level");

        if (result.data.signIn?.user.otp_enabled === false) {
          // otp_enabled must be true for login
          return rejectLogin("auth.mfaRequired", "auth.mfaRequired");
        }

        // validate the MFA code
        clearLogin();
        let authOTPResult;
        try {
          authOTPResult = await client.mutate({
            variables: {
              code: code,
              rememberDevice: false,
            },
            mutation: USER_AUTH_OTP,
            fetchPolicy: "no-cache",
          });

          if (authOTPResult?.data?.authOTP?.user) {
            console.log("MFA code validated");
            localStorage.setItem(MFA_SESSION, true);
          } else {
            console.log("MFA response has no user");
            return rejectLogin("auth.badmfa", "auth.badmfa");
          }
        } catch (error) {
          console.log("MFA response error", error);
          return rejectLogin("auth.badmfa", "auth.badmfa");
        }

        // we need to merge these results so that we retain the session
        // expiration time. our initial expiration time is going to be five
        // minutes because of MFA. We want the new expiration time from the
        // authOTP call to be the one that we use.
        localStorage.setItem(
          SESSION_NAME,
          JSON.stringify({
            expires: authOTPResult.data.authOTP.expires,
            user: result.data.signIn.user,
          })
        );

        localStorage.setItem(JWT_TOKEN, authOTPResult.data.authOTP.token);

        console.log("login: successful");
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (e) {
      console.log(`unexpected message back from graphql: ${e}`);
      return Promise.reject(e.message);
    }
  },
  logout: () => {
    console.log("logout called");
    clearLogin();
    return Promise.resolve();
  },
  checkError: () => {
    // .. react-admin will automatically call logout here.
  },
  checkAuth: async () => {
    // if any of this code throws, react-admin will forcibly logout the user. be
    // aware!
    if (!localStorage.getItem(SESSION_NAME) && location.search) {
      // parent window is sending us a token to use.
      const token = new URLSearchParams(location.search).get('token');
      if (token) {
        console.log(`using token from url: ${token}`);
        const loginResult = await loginWithToken(token);
        if (loginResult) {
          // rewrite the page so that the token is removed from the URL.
          window.history.replaceState(null, null, window.location.href.split("?")[0]);
          return Promise.resolve();
        } else {
          console.log('reject login - bad token');
          return rejectLogin("auth.badtoken", "auth.badtoken");
        }
      }
    }

    // user must have a valid session as well as have passed MFA.
    if (!localStorage.getItem(SESSION_NAME)) {
      console.log('reject login - no SESSION_NAME');
      return rejectLogin("auth.nosession", "auth.nosession");
    }

    /* confirm that the API access hasn't expired yet */
    const sessionInfo = JSON.parse(localStorage.getItem(SESSION_NAME));
    const expires = Date.parse(sessionInfo.expires);
    const now = new Date();

    console.log(
      `checkAuth: expires: ${expires} now: ${now.getTime()} remain: ${expires - now.getTime()
      }`
    );

    if (now.getTime() > expires) {
      // session has expired.
      console.log('reject login - session expired');

      return rejectLogin(
        "auth.expired",
        "checkAuth: destroying session due to expiry or min level failure"
      );
    }

    // verify the login with the mothership -- this can only be done when MFA has been passed.
    // if the admin drops to a standard user account the session is going to not match the data from
    // the api, and we will bounce them out. This mainly works because all admins must have MFA_SESSION.
    if (sessionInfo.user.otp_enabled && localStorage.getItem(MFA_SESSION)) {
      console.log(`verifying login state with GraphQL: GET_ME`);
      let result;
      try {
        // A note about this call - because checkAuth is called frequently by react-admin, we want to
        // cache the result of this call. This is because the user's admin level is not going to change
        // frequently, and we don't want to be making this call every time the user clicks on a link.
        // 
        // Additionally, not caching this call will cause the call to be cancelled on a new page load,
        // as duplicate Apollo Client Queries are cancelled by default (or so it seems.) --jna
        result = await client.query({
          query: GET_ME,
          fetchPolicy: "cache",
        });
      } catch (error) {
        console.log(`verify error: ${error}`);
        return rejectLogin("auth.queryfailed", "auth.queryfailed");
      }
      console.log(`verify returns user: ${result.data.me.user.id}`);

      if (
        result.data.me.user.admin_level <= MIN_ADMIN_LEVEL ||
        result.data.me.user.id !== sessionInfo.user.id
      ) {
        // admin level of at least 10 required for login. Maybe the admin switched accounts on us?
        console.log(
          `checkAuth: destroying session due to min level failure or mismatch: session.user.id=${sessionInfo.user.id}, check returns ${result.data.me.user.id}`
        );

        return rejectLogin("auth.insufficient", "insufficient_admin_level");
      }
    }

    // we're good!
    console.log("checkAuth: authentication is valid and not expired.");

    const mfaSession = localStorage.getItem(MFA_SESSION);
    if (!mfaSession) {
      console.log("sending to MFA page, MFA not completed");
      return Promise.reject();
    }

    return Promise.resolve();
  },
  getPermissions: () => {
    const sessionJSON = localStorage.getItem(SESSION_NAME);
    if (!sessionJSON) {
      return Promise.reject();
    }

    const sessionInfo = JSON.parse(sessionJSON);
    const role = getRoleFromAdminLevel(sessionInfo.user?.admin_level);

    // for now return admin always
    return Promise.resolve(permissions[role]);
  },
};
