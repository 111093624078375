/* eslint react/jsx-key: off */
import React from "react";

import Edit from "../../EditNoUndo";

import {
  useRecordContext,
  DeleteWithConfirmButton,
  FormTab,
  SaveButton,
  SelectInput,
  TabbedForm,
  BooleanInput,
  TextInput,
  Toolbar,
  ReferenceInput,
  FormDataConsumer,
  required,
  maxLength,
  minValue,
  maxValue,
  number
} from "react-admin";
import { makeStyles } from "@mui/styles";

import OrgTitle from "./OrgTitle";
import Aside from "./Aside";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const OrgEditToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
      <DeleteWithConfirmButton />
    </Toolbar>
  );
};

const userOptionRenderer = (choice) => `${choice.last_name}, ${choice.first_name} (${choice.id})`;


const ACCOUNT_TYPES = [
  'ACTIVE',
  'PILOT',
  'ONGOING_PILOT',
  'CLOSED_PILOT',
  'DISABLED',
  'TEST'
];

const CUSTOMER_TYPES = [
  'SME',
  'INDIVIDUAL',
  'ENTERPRISE'
];

const OrgEdit = ({ permissions, ...props }) => {
  const record = useRecordContext();

  return (
    <Edit title={<OrgTitle />} aside={<Aside />} {...props}>
      <TabbedForm toolbar={<OrgEditToolbar />}>
        <FormTab label="org.form.information">

          <TextInput disabled label="Organization ID" source="id" />

          {/* this is how to do a dependent select in react-admin */}
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <ReferenceInput
                  label="Owner"
                  source="user_id"
                  reference="User"
                  sort={{ field: "last_name", order: "ASC" }}
                  perPage={1000}
                  filter={{ organizationId: formData.id }}
                >
                  <SelectInput optionText={userOptionRenderer} validate={required()} />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>

          <TextInput source="name" fullWidth validate={[required(), maxLength(255)]} />
          <TextInput label="Short Name" source="company_short_name" validate={[required(), maxLength(255)]} fullWidth />

          {/* this is how to do a dependent select in react-admin */}
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return (
                <ReferenceInput
                  label="Account Manager"
                  source="account_manager_user_id"
                  reference="User"
                  sort={{ field: "last_name", order: "ASC" }}
                  perPage={1000}
                  filter={{ admins: true }}
                >
                  <SelectInput optionText={userOptionRenderer} />
                </ReferenceInput>
              )
            }}
          </FormDataConsumer>

          <p><strong>Warning: Setting account type to DISABLED or CLOSED_PILOT will shut down all access and polling!</strong></p>

          <SelectInput
            source="customer_type"
            choices={CUSTOMER_TYPES.map((type) => ({ id: type, name: type }))}
            validate={required()}
            style={{ maxWidth: "100%" }}
          />

          <SelectInput
            source="account_type"
            choices={ACCOUNT_TYPES.map((type) => ({ id: type, name: type }))}
            validate={required()}
            style={{ maxWidth: "100%" }}
          />
        </FormTab>
        <FormTab label="org.form.business_rules">
          <TextInput source="week_start" validate={[number(), minValue(0), maxValue(6), required()]} />
          <TextInput source="quarter_start" validate={[number(), minValue(0), maxValue(365), required()]} />
        </FormTab>
        <FormTab label="org.form.security">
          <TextInput label="Max Containers (-1 = unlimited)" source="max_containers" validate={[number(), minValue(-1), required()]} />
          <TextInput label="Max Users (-1 = unlimited)" source="max_users" validate={[number(), minValue(-1), required()]} />

          <BooleanInput label="MFA Mandatory" source="mfa_mandatory" />
        </FormTab>
      </TabbedForm>
    </Edit >
  )
};

export default OrgEdit;
