/* eslint react/jsx-key: off */
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";

import MoveButton from "../MoveButton";
import RenameContainerButton from "../RenameContainerButton";
import IdAndNameField from "../IdAndNameField";

import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  SimpleList,
  TextField,
  TextInput,
  ReferenceField,
  FunctionField,
  ShowButton,
  Button,
  Pagination,
} from "react-admin";

import { Link } from "react-router-dom";

import Aside from "./Aside";

const ContainerBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const containerFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Organization ID" source="organizationId" defaultValue="" />,
  <TextInput label="Parent ID" source="parentId" defaultValue="" />,
];

const ContainerPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ContainerList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={containerFilters}
    // filterDefaultValues={{ role: 'user' }}
    sort={{
      field: "id",
      order: "ASC",
    }}
    aside={<Aside />}
    bulkActionButtons={<ContainerBulkActionButtons />}
    pagination={<ContainerPagination />}
  >
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField
        label="Organization"
        source="organization_id"
        reference="Organization"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />

      <ReferenceField
        label="Parent Container"
        source="parent_id"
        reference="Container"
        link="show"
      >
        <IdAndNameField />
      </ReferenceField>

      <MoveButton label="Change Parent" />
      <RenameContainerButton label="Rename" />

      <FunctionField
        label="ECD Config"
        render={(record) => {
          if (record.ecd_config_id) {
            return (
              <>
                <Button
                  component={Link}
                  to={{
                    pathname: `/ECDConfig/${record.ecd_config_id}/show`,
                    state: {
                      record: {
                        container_id: record && parseInt(record.id, 10),
                        container_name: record && record.name,
                      },
                    },
                  }}
                  icon={VisibilityIcon}
                  label="Show"
                >
                  <VisibilityIcon />
                </Button>
                <Button
                  component={Link}
                  to={{
                    pathname: `/ECDConfig/${record.ecd_config_id}`,
                    state: {
                      record: {
                        container_id: record && parseInt(record.id, 10),
                        container_name: record && record.name,
                      },
                    },
                  }}
                  icon={EditIcon}
                  label="Edit"
                >
                  <EditIcon />
                </Button>
              </>
            );
          }
          return (
            <Button
              component={Link}
              to={{
                pathname: `/ECDConfig/create`,
                state: {
                  record: {
                    container_id: record && parseInt(record.id, 10),
                    container_name: record && record.name,
                  },
                },
              }}
              label="Create"
            >
              <AddIcon />
            </Button>
          );
        }}
      />
      <FunctionField
        label="Edit"
        render={(record) => {
          return (
            <>
              <Button
                component={Link}
                to={{
                  pathname: `/Container/${record.id}`,
                  state: {
                    record: {
                      container_id: record && parseInt(record.id, 10),
                    },
                  },
                }}
                icon={EditIcon}
                label="Edit"
              >
                <EditIcon />
              </Button>
            </>
          );
        }}
      />
      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default ContainerList;
