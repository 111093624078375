import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';

const JWT_HEADER = 'x-alembic-jwt';
const JWT_TOKEN_LS = 'jwtToken';

const httpLinkConfig = {
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include'
};

const directionalLink = new RetryLink().split((req) => {
  const { query } = req;

  const definition = getMainDefinition(query);

  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
},
  new GraphQLWsLink(createClient({
    url: process.env.REACT_APP_WS_URL,
    credentials: 'include',
    connectionParams: () => {
      return { [JWT_HEADER]: localStorage.getItem(JWT_TOKEN_LS) || "" }
    }
  })),
  new HttpLink(httpLinkConfig)
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(JWT_TOKEN_LS);
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      [JWT_HEADER]: token ? token : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(directionalLink),
  cache: new InMemoryCache(),
  credentials: 'include' // This is required to send cookies with the request
});

export default client;

